import {useRef, useState} from "react";
import Login_Password_post from "../../../http/PUT/Update_Password_post";
import {useOutletContext} from "react-router";
import {useParams} from "react-router-dom";

const F_Change_password_page = () => {


    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{4,12}$/;

    const setModalOpen = useOutletContext().setModalOpen;
    const ulp = useOutletContext().UserList_page_change;

    const { id } = useParams(); // useParams를 사용하여 URL의 파라미터를 가져옴

    const [password, setPassword] = useState(false);
    const [confirmPassword,setConfirmPassword] = useState(false);

    return (
        <div className={"user_list_page"}>
            <div className={"change_password_page"}>
                <div className={"change_password_page_body"}>
                    <h1>비밀번호변경</h1>
                    <table>
                        <tbody>
                        <tr>
                            <th>새 비밀번호</th>
                            <td>
                                <input type={"password"} onChange={(e) => setPassword(e.target.value)}/>
                            </td>
                        </tr>
                        <tr>
                            <th>새 비밀번호 확인</th>
                            <td>
                                <input type={"password"} onChange={(e) => setConfirmPassword(e.target.value)}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <button onClick={async () => {


                        if (passwordRegex.test(password)) {
                            if(password === confirmPassword)
                            {
                                let chack = await Login_Password_post(id,password,confirmPassword);

                                if(chack === true)
                                {
                                    alert("비번 변경완료");
                                    ulp();
                                }
                                else if(chack === false)
                                {
                                    setModalOpen(true);
                                }
                                else {
                                    console.log("error 발생");
                                }
                            }
                            else {
                                alert("비번과 비번확인이 다릅니다.");
                            }
                        } else {
                            alert("비밀번호는 4~12자의 길이로 대소문자, 숫자, 특수문자를 최소 1개씩 포함해야 합니다.");
                        }



                    }}>확인
                    </button>
                </div>
            </div>
        </div>
    );
};

export default F_Change_password_page;