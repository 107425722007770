import axios from "axios";

async function Delete_user(accountId) {

    try {
        const response = await axios({
            url: `https://test.admin.clairaudience.co.kr/api/admin/v1/manager/${accountId}`,
            method: 'DELETE',
        });
        return 200;
    } catch (error) {
        if(error.response.status === 401)
        {
            return 401;
        }
        else if(error.response.status === 410)
        {
            console.log('확인 에러: ', error.response.status);
            return 410;
        }
    }

}

export default Delete_user;