import axios from "axios";

async function Login_post(userId,password,)  {

        try {
            const formData = new FormData();
            formData.append('userId', userId);
            formData.append('password', password);

            const response = await axios({
                url: 'https://test.admin.clairaudience.co.kr/api/login',
                method: 'POST',
                data: formData,
                withCredentials: true,
            });
            if (response.status === 200) {
                console.log('로그인 성공! ');
            } else {
                console.log("STATUS : ", response.status);
            }

            return true;
        } catch (error) {
            console.log('로그인 에러: ', error);

            return false;
        }
}

export default Login_post;