import './Admin.css';
import {useEffect, useState} from "react";
import UserList_page from "./UserList/UserList_page";
import Modal_page from "./Modal/Modal_page";

import './UserList/UserList_page.css';
import './UserList/UserList_pagenation.css';
import './UserData/User_Creat/UserCreate_page.css';
import './UserData/User_delete/User_delete.css'
import './UserData/ChangePassword/ChangePassword_page.css'
import './UserData/UpdateExpire/UpdateExpire_page.css'
import './UserData/UserData_page.css'
import './UserData/User_delete/User_delete.css'
import './UserData/ChangeName/ChangeName_page.css'
import './Modal/Modal_page.css'
import Manger_check_get from "./http/GET/Manger_check_get";
import Change_password_page from "./UserData/ChangePassword/ChangePassword_page";
import UpdateExpire_page from "./UserData/UpdateExpire/UpdateExpire_page";
import UserData_page from "./UserData/UserData_page";
import Change_name_page from "./UserData/ChangeName/ChangeName_page";
import UserCreate_page from "./UserData/User_Creat/UserCreate_page";
import {CiBoxList} from "react-icons/ci";
import Logout_post from "./http/POST/logout_post";
import header_logo_w from "./image_test/header_logo_w.svg";
import {VscReactions} from "react-icons/vsc";
import {Outlet, useNavigate} from "react-router-dom";

function useWindowSize() {   //화면 조정
  const [size, setSize] = useState({width: window.innerWidth, height: window.innerHeight});

  useEffect(() => {
    const handleResize = () => {
      setSize({width: window.innerWidth, height: window.innerHeight});
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return size;
}


function login_check_function() {
  let check = Manger_check_get();
  return check;
}

function Admin() {
  const {height} = useWindowSize(); // `height`만 사용하므로 구조 분해 할당을 사용해 추출합니다.
  const [page, setPage] = useState("UserList_page");
  const [data, setData] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();


  const Change_name_page_change = async (id) => {

   let chack = await Manger_check_get();
    if(chack === true)
    {
      navigate(`/admin/userdata_name/${id}`);
    }
    else if(chack === false)
    {
      setModalOpen(true);
    }
    else {
      console.log("error 발생");
    }
  };

  const UserData_page_change = async (accountId) => {

    let chack = await Manger_check_get();
    if(chack === true)
    {
      navigate(`/admin/userdata/${accountId}`);
    }
    else if(chack === false)
    {
      setModalOpen(true);
    }
    else {
      console.log("error 발생");
    }
  };

  const Update_expire_page_change = async (data) => {

    let chack = await Manger_check_get();
    if(chack === true)
    {
      navigate(`/admin/userdata_expire/${data.accountId}/${data.role}`);
    }
    else if(chack === false)
    {
      setModalOpen(true);
    }
    else {
      console.log("error 발생");
    }

  };


  const Change_password_page_change = async (id) => {

    let chack = await Manger_check_get();
    if(chack === true)
    {
      navigate(`/admin/userdata_password/${id}`);
    }
    else if(chack === false)
    {
      setModalOpen(true);
    }
    else {
      console.log("error 발생");
    }

  };



  const UserList_page_change = async () => {

    let chack = await Manger_check_get();
    if(chack === true)
    {
      navigate(`/admin`);
    }
    else if(chack === false)
    {
      setModalOpen(true);
    }
    else {
      console.log("error 발생");
    }
  };

  const UserCreate_page_change = async () => {

    let chack = await Manger_check_get();
    if(chack === true)
    {
      navigate(`/admin/usercreate`);
    }
    else if(chack === false)
    {
      setModalOpen(true);
    }
    else {
      console.log("error 발생");
    }
  };


  return (
      <div className={"main"} style={{width: '100%', height: height}}>
        <Modal_page modalOpen={modalOpen} setModalOpen={setModalOpen}/>
        <div className={"main_head"}>
          <div className="navigation">
            <div
                className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
              <a href="/"
                 className="me-lg-auto text-white ">
                <img src={header_logo_w} alt={"logo"}/>
              </a>

              <ul className="nav ">
                <li>
                  <a className="nav-link text-white" onClick={() => {
                    UserList_page_change();
                  }}>
                    <CiBoxList size={"40"}/>
                    <div>
                      UserList
                    </div>
                  </a>
                </li>
                <li>
                  <a className="nav-link text-white" onClick={() => {
                    UserCreate_page_change();
                  }}>
                    <VscReactions size={"40"}/>
                    <div>
                      UserCreate
                    </div>
                  </a>
                </li>
              </ul>
              <div className="text-end" style={{paddingLeft: "60px"}}>
                <button type="button" className="btn btn-warning" onClick={async () => {
                  const chack = await Logout_post();
                  console.log("kadsjgklsadg");
                  console.log(chack);
                  if (chack) {
                    localStorage.setItem("check","false");
                    window.location.href = "https://test.admin.clairaudience.co.kr/error-401";
                  } else if (chack === 401) {
                    setModalOpen(true);
                  } else {

                  }
                }}>Log out
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={"main_body"}>

          <Outlet context={{setModalOpen,UserList_page_change,Change_password_page_change,
            Update_expire_page_change,UserData_page_change,Change_name_page_change
          }}/>

        </div>
      </div>
  );
}

export default Admin;
