import axios from "axios";

async function F_ExpireSession_get(accountId,setCount,count) {

    axios.post(`https://test.admin.clairaudience.co.kr/api/manager/v1/synesper-lite-family/user/enable/${accountId}`)
        .then(response => {
            console.log("성공");
            const num = count+1;
            setCount(num);
        })
        .catch(error => {
            console.log("실패");
        });


}

export default F_ExpireSession_get;