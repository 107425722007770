import {useRef, useState} from "react";


const Modal_page = ({modalOpen,setModalOpen}) => {

    const modalBackground = useRef();

    return (
        <div>
            {
                modalOpen &&
                <div className={'family-modal-session-container'} ref={modalBackground} onClick={e => {
                    if (e.target === modalBackground.current) {
                        setModalOpen(true);
                    }
                }}>
                    <div className="family-modal-session-content">
                            <div>세션이 만료 되었습니다.</div>
                            <button onClick={() => {
                                window.location.href = "https://test.admin.clairaudience.co.kr/error-401";
                            }}>
                                확인
                            </button>
                    </div>
                </div>
            }
        </div>
    );
};

export default Modal_page;