import axios from "axios";

async function VersionList_get(now_page) {

    //url: `https://admin.clairaudience.co.kr/api/manager/v1/synesper-lite-cardio/user?page=${now_page}&size=20`,

    try {

        const response = await axios({
            url: `https://test.admin.clairaudience.co.kr/api/engineer/v1/synesper-lite-family/version?page=${now_page}&size=20`,
            method: 'GET',
        });
        if (response.status === 200) {
            console.log("성공");
            console.log("STATUS : ", response.data);
            return response.data;
        } else {
            console.log("STATUS : ", response.status);
        }
    } catch (error) {
        console.log('로그인 에러: ', error);
    }


}

export default VersionList_get;