import axios from "axios";


async function Login_Password_post(accountId, password, confirmPassword) {

    try {
        const Data = {
            "password" : password,
            "confirmPassword" : confirmPassword
        };

        const response = await axios({
            headers : {
                "Content-Type":  "application/json"
            },
            url: `https://test.admin.clairaudience.co.kr/api/manager/v1/synesper-lite-family/user/password/${accountId}`,
            method: 'PUT',
            data: Data,
            withCredentials: true
        });
        if (response.status === 200) {
            console.log("성공");
        } else {
            console.log("일단 성공");
        }
        return true;
    } catch (error) {
        if(error.response.status === 401)
        {
            return false;
        }
        else
        {
            return "error";
        }
    }


}

export default Login_Password_post;