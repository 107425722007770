import './Manager.css';
import './Modal/Modal_page.css';
import './MyData/MyData_page.css';
import './MyData/ChangePassword/ChangePassword_page.css';
import './MyData/ChangeName/ChangeName_page.css';
import {useEffect, useState} from "react";
import Manger_check_get from "./http/GET/Manger_check_get";
import Modal_page from "./Modal/Modal_page";
import Change_password_page from "./MyData/ChangePassword/ChangePassword_page";
import MyData_page from "./MyData/MyData_page";
import Change_name_page from "./MyData/ChangeName/ChangeName_page";
import header_logo_w from "./image_test/header_logo_w.svg";
import Logout_post from "./http/POST/logout_post";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Outlet, useNavigate} from "react-router-dom";



function useWindowSize() {   //화면 조정
  const [size, setSize] = useState({width: window.innerWidth, height: window.innerHeight});

  useEffect(() => {
    const handleResize = () => {
      setSize({width: window.innerWidth, height: window.innerHeight});
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return size;
}
function login_check_function() {
  let check = Manger_check_get();
  return check;
}

function Manager() {
  const {height} = useWindowSize(); // `height`만 사용하므로 구조 분해 할당을 사용해 추출합니다.
  const [page, setPage] = useState("Mydata_page_change");
  const [data, setData] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  const Mydata_page_change = async () => {
    let chack = await login_check_function();
    if(chack === "error")
    {
      console.log("error 발생");
    }
    else if(chack === 401)
    {
      setModalOpen(true);
    }
    else {
      navigate("/manager");
    }
  };

  const Change_password_page_change = async () => {
    let chack = await Manger_check_get();
    if(chack === "error")
    {
      console.log("error 발생");
    }
    else if(chack === 401)
    {
      setModalOpen(true);
    }
    else {
      navigate("/manager/mydata_password");
    }

  };

  const Change_name_page_change = async () => {
    let chack = await Manger_check_get();

    if(chack === "error")
    {
      console.log("error 발생");
    }
    else if(chack === 401)
    {
      setModalOpen(true);
    }
    else {
      navigate("/manager/mydata_name");
    }

  };


  return (
      <div className={"main"} style={{width: '100%', height: height}}>
        <Modal_page modalOpen={modalOpen} setModalOpen={setModalOpen}/>
        <div className={"main_head"}>
          <div className="navigation">
            <div
                className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
              <a href="/"
                 className="me-lg-auto text-white ">
                <img src={header_logo_w} alt={"logo"}/>
              </a>


              <div className="text-end" style={{paddingLeft: "60px"}}>
                <button type="button" className="btn btn-warning" onClick={async () => {
                  const chack = await Logout_post();
                  if (chack) {
                    localStorage.setItem("check", "false");
                    window.location.href = "https://test.admin.clairaudience.co.kr/error-401";
                  } else if (chack === 401) {
                    setModalOpen(true);
                  } else {

                  }
                }}>Log out
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={"main_body"}>


          <Outlet context={{setModalOpen,Change_password_page_change,Change_name_page_change,
            Mydata_page_change
          }}/>


        </div>
      </div>
  );
}

export default Manager;
