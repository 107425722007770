import React, {useState} from "react";
import Login_post from "./http_login";

function Login_Page(){
    const [isActive, setIsActive] = useState(false);

    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');

    const toggleVibration = () => {
        setIsActive(true);
        setTimeout(() => setIsActive(false), 200); // 1초 후에 진동 중지
    };


    const login_play = async () => {
        let login_check = await Login_post(userId,password);
        if(login_check===false)
        {
            toggleVibration();
        }
        else{
            setTimeout(()=>{},200);
            setUserId("");
            setPassword("");
            localStorage.setItem("check","true");
            window.location.href = "https://test.admin.clairaudience.co.kr";
        }
    }


    return <div className={"inner"}>
        <div className={isActive ? "login-page vibration" : "login-page"}>
            <div className="login-wrapper">
                <h2>Login</h2>
                <div id="login-form">
                    <input type="text" value={userId} name="userId" placeholder="아이디" onChange={(e) => setUserId(e.target.value)}/>
                    <input type="password" value={password} name="password" placeholder="비밀번호" onChange={(e) => setPassword(e.target.value)}
                           onKeyDown={(event) => {
                               if (event.key === 'Enter') {
                                   event.preventDefault();
                                   login_play();
                               }
                           }}
                    />
                    <input type="submit" value="Login" onClick={async () => {
                        login_play();
                    }}/>
                </div>
            </div>
        </div>
    </div>
}


export default Login_Page;