import './App.css';
import {BrowserRouter, Route, Routes, Link, Navigate} from 'react-router-dom'
import Login from "./Login/Login";
import Manager from "./Manager/Manager";
import Family from "./Family/Family";
import Admin from "./Admin/Admin";


import Error_page from "./Error-401/Error-401";
import Dashboard_page from "./Family/admin_page/DashBoard/DashBoard_page";
import F_UserList_page from "./Family/admin_page/UserList/UserList_page";
import F_UserData_page from "./Family/admin_page/UserData/UserData_page";
import F_Version_page from "./Family/admin_page/Version/Version_page";
import F_Updata_version_page from "./Family/admin_page/Version/Updata_version_page";
import F_Delete_version_page from "./Family/admin_page/Version/Delete_version_page";
import F_Create_version_page from "./Family/admin_page/Version/Create_version_page";
import F_Change_password_page from "./Family/admin_page/UserData/ChangePassword/ChangePassword_page";
import F_UpdateExpire_page from "./Family/admin_page/UserData/UpdateExpire/UpdateExpire_page";
import F_Update_delivery_page from "./Family/admin_page/UserData/Update_delivery_page/Update_delivery_page";
import F_Measure_page from "./Family/admin_page/Measure/Measure_page";
import A_UserList_page from "./Admin/UserList/UserList_page";
import A_UserData_page from "./Admin/UserData/UserData_page";
import A_Change_password_page from "./Admin/UserData/ChangePassword/ChangePassword_page";
import A_Change_name_page from "./Admin/UserData/ChangeName/ChangeName_page";
import A_UpdateExpire_page from "./Admin/UserData/UpdateExpire/UpdateExpire_page";
import A_UserCreate_page from "./Admin/UserData/User_Creat/UserCreate_page";
import MyData_page from "./Manager/MyData/MyData_page";
import M_Change_password_page from "./Manager/MyData/ChangePassword/ChangePassword_page";
import M_Change_name_page from "./Manager/MyData/ChangeName/ChangeName_page";


function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/login"/>}/>
          <Route path={"/login"} element={<Login/>}/>
          <Route path={"/manager"} element={<Manager/>}>
            <Route path={""} element={<MyData_page/>}/>
            <Route path={"mydata_password"} element={<M_Change_password_page/>}/>
            <Route path={"mydata_name"} element={<M_Change_name_page/>}/>
          </Route>
          <Route path={"/admin"} element={<Admin/>}>
            <Route path={""} element={<A_UserList_page/>}/>
            <Route path={"userdata/:id"} element={<A_UserData_page/>}/>
            <Route path={"userdata_password/:id"} element={<A_Change_password_page/>}/>
            <Route path={"userdata_name/:id"} element={<A_Change_name_page/>}/>
            <Route path={"userdata_expire/:id/:role"} element={<A_UpdateExpire_page/>}/>
            <Route path={"usercreate"} element={<A_UserCreate_page/>}/>
          </Route>
          <Route path={"/synesper-lite-family"} element={<Family/>}>
            <Route path={""} element={<Dashboard_page/>}/>
            <Route path={"userlist"} element={<F_UserList_page/>}/>
            <Route path={"userdata/:id"} element={<F_UserData_page/>}/>
            <Route path={"userdata_password/:id"} element={<F_Change_password_page/>}/>
            <Route path={"userdata_expire/:id/:date"} element={<F_UpdateExpire_page/>}/>
            <Route path={"userdata_delivery/:id/:name/:date"} element={<F_Update_delivery_page/>}/>
            <Route path={"measure/:id"} element={<F_Measure_page/>}/>
            <Route path={"version_page"} element={<F_Version_page/>}/>
            <Route path={"version_page_updata/:type"} element={<F_Updata_version_page/>}/>
            <Route path={"version_page_delete/:type"} element={<F_Delete_version_page/>}/>
            <Route path={"version_page_create"} element={<F_Create_version_page/>}/>
          </Route>

          <Route path={"/error-401"} element={<Error_page/>}/>

        </Routes>
      </BrowserRouter>
  );
}

export default App;
