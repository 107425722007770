import axios from "axios";

async function Logout_post()  {

    try {
        const formData = new FormData();

        const response = await axios({
            url: 'https://test.admin.clairaudience.co.kr/api/logout',
            method: 'POST',
        });
        if (response.status === 200) {
        } else {
        }

        return true;
    } catch (error) {
        return false;
    }
}

export default Logout_post;